<template>
	<div>
		<div class="mt-12 px-12" v-if="pageLoading">
			<h2>Loading...</h2>
			<v-progress-linear height="12" color="orange" indeterminate></v-progress-linear>
		</div>
		<div :style="[inlineStyle]" class="overflow-y" v-else-if="activity && activity.length">
			<v-timeline dense class="pt-3 mr-3">
				<v-timeline-item
					class="pb-5"
					v-for="(logs, index) in activity"
					:key="index"
					color="blue darken-4"
					small
				>
					<ActivityTab :open_details="true" :log="logs" :title="title"></ActivityTab>
				</v-timeline-item>
			</v-timeline>
		</div>
		<template v-else>
			<p class="m-0 row-not-found text-center">
				<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
				Uhh... There are no activity at the moment.
			</p>
		</template>
	</div>
</template>
<script>
// import ActivityTab from "@/view/pages/leads/components/ActivityTab";
import { QUERY } from "@/core/services/store/request.module";

export default {
	name: "ActivityTimelineView",
	props: {
		relatedId: {
			type: [String, Number],
			default: null,
		},
		endpoint: {
			type: String,
			default: "activity-log",
		},
		module: {
			type: String,
			default: null,
			required: true,
		},
		title: {
			type: String,
			default: "",
		},
		loaded: {
			type: Boolean,
			required: true,
			default: false,
		},
		inlineStyle: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		getActivity() {
			const ID = this.relatedId; /* Id of the Record */
			const MODULE = this.module; /* Whose Activity will be being shown */
			const ENDPOINT = this.endpoint; /* endpoint */

			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: ENDPOINT,
					data: {
						module: MODULE,
						id: ID,
						/* added_by: this.role_id == 9 || this.role_id == 10 || this.role_id == 11 ? this.role_id : null, */
						user_filter: null /* for further */,
					},
				})
				.then((data) => {
					if (data.length) {
						this.activity = data;
					}
					this.$emit("loaded", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	created() {
		const ID = this.relatedId; /* Id of the Record */
		const MODULE = this.module; /* Whose Activity will be being shown */
		/* const LOADED = this.loaded; */
		/* Is Data loaded */
		if (ID && MODULE) {
			this.getActivity();
		}
	},
	components: {
		ActivityTab: () => import("@/view/pages/leads/components/ActivityTab"),
	},
	watch: {
		relatedId() {
			this.getActivity();
		},
	},
	data() {
		return {
			activity: [],
			role_id: null,
			pageLoading: false,
		};
	},
};
</script>
